export function TemplateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.96891 0C2.96039 0 2.14282 0.81252 2.14282 1.81481V12.1852C2.14282 13.1875 2.96039 14 3.96891 14H12.3167C13.3253 14 14.1428 13.1875 14.1428 12.1852V3.73414C14.1428 3.32205 14.0017 2.92223 13.7427 2.60044L12.1977 0.681109C11.8511 0.250607 11.3265 0 10.7717 0H3.96891ZM3.70804 1.81481C3.70804 1.67163 3.82483 1.55556 3.96891 1.55556H10.7717C10.851 1.55556 10.9259 1.59136 10.9754 1.65286L12.5204 3.57219C12.5574 3.61816 12.5776 3.67527 12.5776 3.73414V12.1852C12.5776 12.3284 12.4608 12.4444 12.3167 12.4444H3.96891C3.82483 12.4444 3.70804 12.3284 3.70804 12.1852V1.81481ZM5.26196 5.62393C5.26196 5.19438 5.58432 4.84615 5.98196 4.84615H10.302C10.6996 4.84615 11.022 5.19438 11.022 5.62393C11.022 6.05349 10.6996 6.40171 10.302 6.40171H5.98196C5.58432 6.40171 5.26196 6.05349 5.26196 5.62393ZM5.26196 8.73504C5.26196 8.30548 5.58432 7.95726 5.98196 7.95726H10.302C10.6996 7.95726 11.022 8.30548 11.022 8.73504C11.022 9.16459 10.6996 9.51282 10.302 9.51282H5.98196C5.58432 9.51282 5.26196 9.16459 5.26196 8.73504Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6824 0C16.6739 0 15.8563 0.81252 15.8563 1.81481V12.1852C15.8563 13.1875 16.6739 14 17.6824 14H26.0302C27.0388 14 27.8563 13.1875 27.8563 12.1852V3.73414C27.8563 3.32205 27.7152 2.92223 27.4562 2.60044L25.9112 0.681109C25.5646 0.250607 25.04 0 24.4852 0H17.6824ZM17.4215 1.81481C17.4215 1.67163 17.5383 1.55556 17.6824 1.55556H24.4852C24.5645 1.55556 24.6394 1.59136 24.6889 1.65286L26.2339 3.57219C26.2709 3.61816 26.2911 3.67527 26.2911 3.73414V12.1852C26.2911 12.3284 26.1743 12.4444 26.0302 12.4444H17.6824C17.5383 12.4444 17.4215 12.3284 17.4215 12.1852V1.81481ZM18.9763 5.62393C18.9763 5.19438 19.2987 4.84615 19.6963 4.84615H24.0163C24.4139 4.84615 24.7363 5.19438 24.7363 5.62393C24.7363 6.05349 24.4139 6.40171 24.0163 6.40171H19.6963C19.2987 6.40171 18.9763 6.05349 18.9763 5.62393ZM18.9763 8.73504C18.9763 8.30548 19.2987 7.95726 19.6963 7.95726H24.0163C24.4139 7.95726 24.7363 8.30548 24.7363 8.73504C24.7363 9.16459 24.4139 9.51282 24.0163 9.51282H19.6963C19.2987 9.51282 18.9763 9.16459 18.9763 8.73504Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.96891 16C2.96039 16 2.14282 16.8125 2.14282 17.8148V28.1852C2.14282 29.1875 2.96039 30 3.96891 30H12.3167C13.3253 30 14.1428 29.1875 14.1428 28.1852V19.7341C14.1428 19.3221 14.0017 18.9222 13.7427 18.6004L12.1977 16.6811C11.8511 16.2506 11.3265 16 10.7717 16H3.96891ZM3.70804 17.8148C3.70804 17.6716 3.82483 17.5556 3.96891 17.5556H10.7717C10.851 17.5556 10.9259 17.5914 10.9754 17.6529L12.5204 19.5722C12.5574 19.6182 12.5776 19.6753 12.5776 19.7341V28.1852C12.5776 28.3284 12.4608 28.4444 12.3167 28.4444H3.96891C3.82483 28.4444 3.70804 28.3284 3.70804 28.1852V17.8148ZM5.26196 21.6239C5.26196 21.1944 5.58432 20.8462 5.98196 20.8462H10.302C10.6996 20.8462 11.022 21.1944 11.022 21.6239C11.022 22.0535 10.6996 22.4017 10.302 22.4017H5.98196C5.58432 22.4017 5.26196 22.0535 5.26196 21.6239ZM5.26196 24.735C5.26196 24.3055 5.58432 23.9573 5.98196 23.9573H10.302C10.6996 23.9573 11.022 24.3055 11.022 24.735C11.022 25.1646 10.6996 25.5128 10.302 25.5128H5.98196C5.58432 25.5128 5.26196 25.1646 5.26196 24.735Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6824 16C16.6739 16 15.8563 16.8125 15.8563 17.8148V28.1852C15.8563 29.1875 16.6739 30 17.6824 30H26.0302C27.0388 30 27.8563 29.1875 27.8563 28.1852V19.7341C27.8563 19.3221 27.7152 18.9222 27.4562 18.6004L25.9112 16.6811C25.5646 16.2506 25.04 16 24.4852 16H17.6824ZM17.4215 17.8148C17.4215 17.6716 17.5383 17.5556 17.6824 17.5556H24.4852C24.5645 17.5556 24.6394 17.5914 24.6889 17.6529L26.2339 19.5722C26.2709 19.6182 26.2911 19.6753 26.2911 19.7341V28.1852C26.2911 28.3284 26.1743 28.4444 26.0302 28.4444H17.6824C17.5383 28.4444 17.4215 28.3284 17.4215 28.1852V17.8148ZM18.9763 21.6239C18.9763 21.1944 19.2987 20.8462 19.6963 20.8462H24.0163C24.4139 20.8462 24.7363 21.1944 24.7363 21.6239C24.7363 22.0535 24.4139 22.4017 24.0163 22.4017H19.6963C19.2987 22.4017 18.9763 22.0535 18.9763 21.6239ZM18.9763 24.735C18.9763 24.3055 19.2987 23.9573 19.6963 23.9573H24.0163C24.4139 23.9573 24.7363 24.3055 24.7363 24.735C24.7363 25.1646 24.4139 25.5128 24.0163 25.5128H19.6963C19.2987 25.5128 18.9763 25.1646 18.9763 24.735Z'
      />
    </svg>
  );
}
