function ShareIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
    >
      <path
        fillRule='evenodd'
        d='M6.37 4.526v4.289a.685.685 0 001.371 0V4.526h.95a.2.2 0 00.155-.326l-1.48-1.819a.4.4 0 00-.62 0l-1.48 1.82a.2.2 0 00.155.325h.95zM12 7.95a.65.65 0 10-1.3 0V11H3.3V7.95a.65.65 0 10-1.3 0V11.65c0 .359.291.65.65.65h8.7a.65.65 0 00.65-.65v-3.7z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export { ShareIcon };
