const ExitIcon = (): JSX.Element => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.2333 12.7559V2.2317H5.26667V6.10874C5.26667 6.45852 4.98311 6.74207 4.63333 6.74207C4.28355 6.74207 4 6.45852 4 6.10874V2.2317C4 1.40136 4.56711 1 5.26667 1H12.2333C12.9329 1 13.5 1.40136 13.5 2.2317V12.7559C13.5 13.5862 12.9329 14 12.2333 14H5.26667C4.56711 14 4 13.5862 4 12.7559V11.8858C4 11.536 4.28355 11.2525 4.63333 11.2525C4.98311 11.2525 5.26667 11.536 5.26667 11.8858V12.7559H12.2333Z'
        fill='white'
      />
      <path
        d='M6.81469 9.74126H2.52631V10.6913C2.52631 10.8597 2.33075 10.9527 2.20007 10.8464L0.381223 9.36619C0.184532 9.20612 0.184533 8.90577 0.381224 8.7457L2.20007 7.2655C2.33075 7.15915 2.52631 7.25214 2.52631 7.42062V8.37063H6.81469C7.19318 8.37063 7.5 8.67745 7.5 9.05594C7.5 9.43443 7.19318 9.74126 6.81469 9.74126Z'
        fill='white'
      />
    </svg>
  );
};

export { ExitIcon };
